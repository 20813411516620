import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Andy Kohv - Software Engineer"
      keywords={[
        `software`,
        `engineering`,
        `golang`,
        `tallinn`,
        `estonia`,
        `architecture`,
      ]}
    />
    <div style={{ maxWidth: `300px`, marginBottom: `1rem`, marginLeft: `1rem`, float: "right" }}>
      <StaticQuery
        query={graphql`
          query {
            placeholderImage: file(relativePath: { eq: "kohv-resize.jpeg" }) {
              childImageSharp {
                fixed(width: 300, height: 210, quality: 100) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        `}
        render={data => (
          <Img fixed={data.placeholderImage.childImageSharp.fixed} />
        )}
      />
    </div>

    <p>
      {[
        "I'm working in the core team at Pipedrive to build better backend services with both JS, and Go Programming Language.",
      ].join("")}
    </p>
    <p>
      {[
        "During my first years in Pipedrive, I began to work on the Backoffice project, which was a fully custom-built platform ",
        "used to manage customers ",
        ""
      ].join("")}
    </p>
    <p>
      {[
        "After that project was ready & launched, I soon joined Core team and began working on a customer data migration service, ",
        "which allows migrating fully all of the customer's data between USA & Europe datacenters. After that project I moved to ",
        "work on an existing backend gateway project which routes between different microservices and finally serves the client response. ",
      ].join("")}
    </p>

    <p>
      {
        "I work daily with such tech: "
      }
      <a href="https://golang.org">Go</a>,{" "}
      <a href="https://nodejs.org/en/">Node.js</a>,{" "}
      <a href="https://reactjs.org/">React</a>, {" "}
      <a href="https://kubernetes.io/">Kubernetes</a>,{" "}
    </p>
  </Layout>
)

export default IndexPage
